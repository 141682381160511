import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo";
import { Link, withPrefix } from 'gatsby';
import Helmet from "react-helmet"
import RichText from '../components/richText';



const BuenasPracticas = (props) => {

  const showCrediton =false;
  const assets = 'assets'


  //Metadata
  const $metadata = props.data.prismic.allMetadatas.edges[0].node;

  //Footer
  const $footer = { data: props.data.prismic.allFooters.edges[0].node, infoPopup: props.data.prismic.allInformacion_financieras.edges[0].node }
  const $layoutData = { $metadata, $footer };



  return (

    <>

      <Layout data={$layoutData} footer={true} className={'u-facturacion'}>


        <SEO metadata={$metadata} title={'Código de buenas prácticas'}></SEO>


        <>

          <Helmet>

          <link href={withPrefix(`../../${assets}/css/fixed/icons.css`)} rel="stylesheet" type="text/css" />
            <link href={withPrefix(`../../${assets}/css/fixed/fonts.css`)} rel="stylesheet" type="text/css" />
            <link href={withPrefix(`../../${assets}/css/fixed/screen.css?v=10`)} rel="stylesheet" type="text/css" />
            <link href={withPrefix(`../../${assets}/css/site/form.css?v=2`)} rel="stylesheet" type="text/css" />
            <link href={withPrefix(`../../${assets}/css/site/financial-info.css?v=4`)} rel="stylesheet" type="text/css" />


          </Helmet>


          <div className="o-wrapper o-section o-section--large">
            <h2 className={`c-title c-title--center c-title--blue ${showCrediton && 'c-title--red'}`}>Código de buenas prácticas</h2>
            <div className="s-content o-wrapper o-wrapper--xs">

            <h2>1. Introducción</h2>
            <p>En cumplimiento con los requisitos del Banco Central del Uruguay ("BCU") y basado en su compromiso con la excelencia, el directorio de VOLMET S.A. ("VOLMET") ha adoptado el presente Código de Buenas Prácticas (el "Código") donde se estipulan los principios y valores generales que rigen las actuaciones y los estándares de comportamiento que se espera de todos los integrantes de la organización, incluyendo su personal superior, en las relaciones que establezcan con los clientes de la VOLMET.</p>

            <h2>2. Ámbito de Aplicación y Difusión</h2>
            <p>El Código se aplica, con carácter obligatorio, a todos los miembros e integrantes de VOLMET, incluso a aquellos contratados a término o que se encuentren prestando funciones en el período de prueba, así como al Personal Superior y a los miembros del Directorio. Aquellos integrantes de VOLMET que desempeñan cargos de "Personal Superior" son además responsables de buscar en forma constante y proactiva maneras de mejorar y expandir la aplicación de los principios de este Código.</p>
            <p>La apropiada aplicación de los principios contenidos en el presente Código requiere que todos los empleados, sin distinción de nivel jerárquico, conozcan y comprendan cabalmente su contenido. Cualquier duda o controversia sobre su contenido o sobre comportamientos éticos en el desempeño de las tareas deberán ser formuladas al Oficial de Cumplimiento. La aplicación de las disposiciones del Código se llevará a cabo teniendo en cuenta el compromiso de la institución de utilizarlas como instrumento destinado a mejorar la calidad y la transparencia en la información que se suministra al cliente.</p>
            <p>Es obligación de los integrantes de VOLMET la lectura, conocimiento y cumplimiento de lo establecido en el presente Código. Cualquier duda o controversia sobre sus contenidos o sobre comportamientos éticos en el desempeño de las tareas deberán ser formuladas por escrito ante el Oficial de Cumplimiento.</p>
            <p>El presente Código se incorporará en el sitio de Internet de VOLMET para facilitar su consulta por parte de cualquier interesado. Asimismo, cada empleado de VOLMET recibirá una copia papel y deberá firmar una copia en señal de conocimiento y aceptación de los términos del presente Código. Sin perjuicio de ello, el referido documento se encontrará a disposición de quien lo solicite personalmente en las oficinas de VOLMET.</p>
            <p>A los efectos de este Código, se entiende por “Cliente” a todo aquel que tenga una relación contractual - en sentido amplio - con VOLMET.</p>

            <h2>3. Principios Básicos que Rigen la Relación con los Clientes</h2>
            <p>La relación con los Clientes deberá conducirse atendiendo a los principios que se detallan seguidamente:</p>
            <ul>
                <li>Velar siempre por los intereses de sus clientes y tratarlos justamente, actuando con integridad.</li>
                <li>Brindar a los clientes toda la información necesaria de los productos y servicios que ofrezcan, en una manera clara, suficiente, veraz y oportuna, evitando la omisión de datos esenciales que sean capaces de inducirlo al error.</li>
                <li>Actuar en todo momento y cualquier sean las circunstancias con profesionalismo, cuidado y diligencia con sus clientes, de acuerdo con los mejores usos y costumbres de la industria.</li>
                <li>Informar sobre los principales riesgos en que se incurre en el uso de los productos o servicios contratados, mediante una forma de comunicación efectiva distinta del contrato.</li>
            </ul>

            <h3>3.1. Transparencia</h3>
            <p>La transparencia en la relación con los clientes constituye un valor fundamental. En tal sentido, VOLMET deberá brindar a los clientes información clara, precisa, suficiente, veraz y oportuna sobre las características de los servicios y productos financieros que ofrece. En todo momento se evitará la omisión de datos información que de alguna forma puedan inducir a error al cliente. En particular, se deberá informar al cliente de forma clara y concisa respeto de los (i) costos y (ii) riesgos que asume con la contratación y uso de los productos mediante una forma de comunicación efectiva distinta del contrato.</p>
            <p>Todo colaborador de VOLMET debe actuar como asesor financiero del cliente, procurando darle todos los elementos informativos y adaptando la oferta a los mejores intereses del cliente.</p>

            <h3>3.2. Veracidad y Diligencia</h3>
            <p>VOLMET deberá actuar con el máximo grado de integridad en todos los niveles, velando por los intereses de sus clientes y trabajando para mejorar la calidad y competencia de su servicio.</p>
            <p>VOLMET prestará sus servicios con profesionalismo, cuidado y diligencia con sus clientes, de acuerdo con los mejores usos y costumbres de la industria.</p>
            <p>En aquellas ocasiones en que se detecte la existencia de un conflicto de intereses que pueda afectar la objetividad profesional del empleado frente al cliente, el mismo deberá ser revelado, en forma inmediata, siguiendo los canales de reporte establecidos. De esta forma, VOLMET S.A. creará un ambiente propicio para la fidelización del cliente y para el desarrollo de una relación profesional basada en la confianza.</p>

            <h3>3.3. Confidencialidad</h3>
            <p>VOLMET guardará reserva y confidencialidad respecto de la información vinculada con sus clientes y los productos financieros que estos adquieren, en los términos de la legislación vigente. Los negocios y asuntos privados de los clientes, la información y documentación proporcionada en su solicitud de crédito no deben ser revelados bajo ningún concepto a ningún otro cliente, ni a terceros, sin el consentimiento expreso y por escrito del cliente.</p>

            <h2>4. Buenas Prácticas Respecto a Productos y Servicios</h2>
            <p>VOLMET se compromete a actuar frente al cliente de manera leal, diligente y transparente en relación con los productos y servicios ofrecidos, de acuerdo con las disposiciones legales y reglamentarias aplicables, así como con los principios establecidos en el presente Código.</p>
            <p>Se presentan a continuación las buenas prácticas adoptadas por VOLMET. Las mismas, que reflejan el compromiso asumido por su Dirección con el buen funcionamiento de los productos que VOLMET ofrece y dan concreción a los principios establecidos en el capítulo anterior.</p>

            <h3>4.1. Relacionamiento con los Clientes</h3>
            <p>Se deberá informar al cliente de manera veraz, completa, precisa y oportuna acerca del funcionamiento, los requisitos, las características y los costos de los productos y servicios que VOLMET ofrece, para facilitarle al cliente la elección del producto o servicio que, en base a la información que provea el cliente, se ajuste más a sus necesidades.</p>
            <p>En los contratos que celebre con sus clientes, VOLMET utilizará un lenguaje claro y accesible, a efectos de facilitar la ejecución e interpretación de estos. En las relaciones contractuales, VOLMET actuará siempre de buena fe, buscando armonizar adecuadamente los intereses de las partes, evitando generar desequilibrios injustificados en perjuicio del cliente.</p>
            <p>En la redacción de los contratos, VOLMET evitará la inclusión de cláusulas abusivas, en los términos de la legislación vigente.</p>
            <p>Los contratos y las distintas informaciones que VOLMET brinde al Cliente serán siempre en idioma español, salvo que el Cliente resida en un país cuyo idioma oficial sea diferente. Cuando se celebre un contrato se pondrá a disposición del cliente una copia del mismo.</p>
            <p>Cualquier modificación en las condiciones generales o particulares de los contratos celebrados con el cliente, requerirá el consentimiento del mismo, salvo cuando dicha modificación produzca una reducción en los cargos a abonar por el cliente. VOLMET recibirá diligentemente cualquier reclamo y/o queja por parte de sus clientes, mediante su sistema de atención a reclamos, atendiendo a los mismos de manera justa, objetiva y diligente.</p>

            <h3>4.2. Evaluación de la Capacidad de Pago</h3>
            <p>VOLMET llevará a cabo un análisis exhaustivo de la capacidad de pago del cliente, antes de la aprobación de cualquier crédito, con el fin de evitar el sobreendeudamiento del cliente y el riesgo de impago. Dicho análisis se basará en la información proporcionada por el cliente y en la que VOLMET pueda recabar de otras fuentes fiables.</p>
            <p>VOLMET procurará que sus clientes cuenten con productos financieros adecuados a sus necesidades y capacidad de pago. En tal sentido, no ofrecerá ni promoverá productos que impliquen para el cliente una carga financiera superior a su capacidad de pago.</p>

            <h3>4.3. Información Financiera</h3>
            <p>En caso de productos de ahorro o inversión, VOLMET deberá informar al cliente sobre los principales riesgos en que se incurre con dichos productos. En la medida en que sea aplicable, se deberá informar también sobre la política de inversiones y la naturaleza de los activos subyacentes.</p>

            <h2>5. Prevención de Conflictos de Interés</h2>
            <p>Se entiende por conflicto de interés aquellas situaciones en las que los intereses de VOLMET y los de un cliente entren en conflicto de manera directa o indirecta, o cuando surjan intereses contrapuestos entre dos clientes. En todos los casos, VOLMET debe velar por el interés de sus clientes, sin perjuicio de los intereses de la empresa.</p>
            <p>La política de prevención de conflictos de interés tiene por objetivo identificar y gestionar de manera adecuada los conflictos de interés que puedan surgir en el curso de las actividades desarrolladas por VOLMET. En este sentido, todos los empleados de VOLMET deben actuar con lealtad, objetividad y transparencia, evitando situaciones que puedan dar lugar a conflictos de interés.</p>
            <p>Los empleados de VOLMET deben comunicar de inmediato a su superior jerárquico y al Oficial de Cumplimiento cualquier situación que pueda generar un conflicto de interés, de acuerdo con los procedimientos internos establecidos por VOLMET.</p>

            <h3>5.1. Situaciones Potenciales de Conflicto de Interés</h3>
            <p>Algunas situaciones potenciales de conflicto de interés pueden incluir, pero no se limitan a:</p>
            <ul>
                <li>Cuando un empleado de VOLMET tenga intereses personales o familiares en las actividades de un cliente.</li>
                <li>Cuando un empleado de VOLMET reciba beneficios o regalos de un cliente que puedan comprometer su objetividad.</li>
                <li>Cuando existan relaciones financieras o comerciales entre VOLMET y un cliente que puedan influir en la imparcialidad de los servicios prestados.</li>
            </ul>

            <h3>5.2. Procedimientos para la Gestión de Conflictos de Interés</h3>
            <p>VOLMET ha establecido procedimientos internos para la identificación, prevención y gestión de conflictos de interés. Estos procedimientos incluyen, entre otros:</p>
            <ul>
                <li>La declaración de intereses personales por parte de los empleados.</li>
                <li>La prohibición de aceptar regalos o beneficios que puedan comprometer la objetividad del empleado.</li>
                <li>La segregación de funciones y responsabilidades para evitar la influencia indebida en la toma de decisiones.</li>
            </ul>

            <h2>6. Mecanismos para la Resolución de Diferencias con los Clientes</h2>
            <p>VOLMET dispone de mecanismos efectivos para la resolución de diferencias y controversias con sus clientes. Estos mecanismos incluyen:</p>
            <ul>
                <li>Un sistema de atención a reclamos y quejas, mediante el cual los clientes pueden presentar sus inquietudes y recibir una respuesta oportuna.</li>
                <li>La posibilidad de recurrir a instancias de mediación o arbitraje, en caso de que no se logre una solución satisfactoria a través del sistema de atención a reclamos.</li>
                <li>La disposición de un Defensor del Cliente, cuya función es actuar como intermediario entre VOLMET y los clientes, velando por el cumplimiento de los principios establecidos en este Código.</li>
            </ul>

            <h2>7. Canales de Reporte</h2>
            <p>VOLMET ha establecido canales de reporte para que los empleados puedan comunicar cualquier situación que consideren contraria a los principios y valores establecidos en este Código. Estos canales incluyen:</p>
            <ul>
                <li>Un canal de denuncias interno, donde los empleados pueden reportar conductas inapropiadas o contrarias a los principios de este Código.</li>
                <li>La posibilidad de reportar directamente al Oficial de Cumplimiento, quien se encargará de investigar y gestionar las denuncias recibidas.</li>
            </ul>

            <h2>8. Sanciones</h2>
            <p>El incumplimiento de las disposiciones establecidas en este Código puede dar lugar a sanciones disciplinarias, de acuerdo con la gravedad de la falta y conforme a los procedimientos internos de VOLMET. Las sanciones pueden incluir:</p>
            <ul>
                <li>Amonestaciones verbales o escritas.</li>
                <li>Suspensión temporal de funciones.</li>
                <li>Despido, en casos de faltas graves o reincidencia.</li>
            </ul>
            <p>VOLMET se compromete a aplicar las sanciones de manera justa y objetiva, garantizando el derecho a la defensa de los empleados involucrados.</p>


            </div>
          </div>



        </>


      </Layout>


    </>
  );
}



export const query = graphql`
query PracticasQuery {
  prismic {
    allMetadatas  {
      edges {
        node {
          ...MetadataFragment
        }
      }
    },
    allFooters {
      edges {
        node {
          ...FooterFragment
        }
      }
    },
    allInformacion_financieras {
      edges {
        node {
          ...InformacionFinancieraFragment
        }
      }
    }
   
  }
}
`


export default BuenasPracticas;

